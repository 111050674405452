// import 'vite/dynamic-import-polyfill'
import * as Turbo from '@hotwired/turbo'
// import ScrollReveal from 'scrollreveal'
import anime from 'animejs/lib/anime.es.js';
import Rellax from 'rellax'
import { splitCharTitle } from './splitText'
import { getCookie, setCookie } from './cookies'
import { initSlider } from './slider'
import { toc } from './toc'
import { $ } from './_helpers'

// import { setCookie } from './cookies'
import { isMobile } from './userAgent'
import { doNotPreventScroll4Anime, loadAnimation, loadContent } from './initPage'
import { whichTransitionEvent } from './transitionEvent'
import '../sass/general/_minireset.scss'
import '../sass/main.scss'
// import '../sass/elements/_vari.scss'


Turbo.start()
loadAnimation()
// if (getCookie('first_time')) $('.preloader')?.remove()

document.addEventListener('turbo:render', (e) => {
    // setCookie('first_time', false, 30)
    doNotPreventScroll4Anime()
    
    if($('.page-title') && !window.mobileVersion) {
        splitCharTitle()
    }
    if (!getCookie('first_time')) {
        loadAnimation()
    } else {
        $('.preloader')?.remove()
    }
    loadContent()
    initJSEventOnDomReady()
})

if (import.meta.hot) {
    import.meta.hot.accept((newModule) => {
    //   console.log('updated: count is now... : ', newModule.count)
    })
}

window.mobilecheck = isMobile()
doNotPreventScroll4Anime()

const initJSEventOnDomReady = () => {

    if(document.querySelector('.rellax')) {
        var rellax = new Rellax('.rellax');
    }

    const setStylesOnElement = function(styles, element){
        Object.assign(element.style, styles);
    }

    const logger = (e) => {
        nav.removeEventListener(transition, logger)
    }

    const transition = whichTransitionEvent() 
    const body = document.querySelector('body');
    const nav = document.querySelector('#mainNav');
    const navLayer = document.querySelector('.main-nav--layer');
    const toggleMenu = document.querySelector('#toggleMenu');
    const mainLogo = document.querySelector('.userstories-logo');
    const vSlider = document.querySelector('.vSlider');
    const cardCTA = document.querySelectorAll('.card-cta');
    
    
    if(cardCTA) {
        cardCTA.forEach(el => {
            el.addEventListener('click', e => {
                console.log(e)
                if(!el.querySelector('.card-link')) return
                el.querySelector('.card-link').click()
            })
        })
    }

    if($('#scroll2Top')) {
        $('#scroll2Top').addEventListener('click', (e) => {
            window.scroll({top: 0, left: 0, behavior: 'smooth'});
        })
    }


    if(vSlider) initSlider(vSlider)

    if (window.mobileVersion) {
        const animationNavItem = anime({
            targets: nav.querySelectorAll('a'),
            opacity: [0, 1],
            translateY: ['1rem', 0],
            autoplay: false,
            easing: "easeOutCubic",
            elasticity: 400,
            duration: 300,
            delay: anime.stagger(35),
            complete: () => nav.removeEventListener(transition, transitionNavItem)
        })
    
        const toggleNav = (e) => {
            console.log('toggle nav')
            body.classList.toggle('overflow-hidden')
            nav.addEventListener(transition, transitionNavItem);
            if(nav.classList.contains('active')) {
                animationNavItem.reverse()
                animationNavItem.play()
            }
            nav.classList.toggle('active');
            toggleMenu.classList.toggle('active');
            navLayer.classList.toggle('active');
            mainLogo.classList.toggle('inverse');
        }

        const transitionNavItem = (e) => {
            if(nav.classList.contains('active') && e.propertyName === 'transform') {
                if(animationNavItem.direction === 'reverse') animationNavItem.reverse()
                animationNavItem.play()
            }
        }
    
        toggleMenu.addEventListener('click', toggleNav, false);
        navLayer.addEventListener('click', toggleNav, false);
    }

    const heroHeader = document.querySelector('.header-hero')
    const actionBar = document.querySelector('.action-bar')
    const stickyFaker = document.querySelector('.stiky-faker')
    const mainContent = document.querySelector('.page-content')
    let stickyTelexObserver

    if(!!window.IntersectionObserver){

        stickyTelexObserver = new IntersectionObserver((entries, observer) => {
            console.log(entries)
            // no intersection with screen
            if(entries[0].isIntersecting) {
                if(actionBar && !actionBar.classList.contains('is-sticky')) actionBar.classList.add("is-sticky")
            } else {
                if(actionBar && actionBar.classList.contains('is-sticky')) actionBar.classList.remove("is-sticky")
            }
        }, { threshold: [0,1] });
        
        // if (heroHeader) stickyTelexObserver.observe(heroHeader)
        if (mainContent) stickyTelexObserver.observe(mainContent)
    }



    let triggerAnim = false

    
    // const actionButton = document.querySelectorAll('[data-button-action]')

    // actionButton.forEach((el, idx) => {
    //     el.addEventListener('click', (e) => {
    //         e.preventDefault()

    //     })
    // })

    const elastickStack = document.querySelector('.pers100');
    const slider = document.querySelector('.glide');
    const togglerItems = document.querySelectorAll('.toggle-trigger');

    const toggleItem = (e) => {
        e.preventDefault();
        if(e.target.classList.contains('active')) return;
        let targetEl = document.getElementById(e.target.getAttribute('data-item-toggle'));
        // let targetHeight = targetEl.getBoundingClientRect().height;
        // if (window.innerHeight > 768) {
        //     document.querySelector('.block-citation').style.setProperty('--maxQuoteHeight', `${targetHeight}px`);
        // } else {
        //     document.querySelector('.block-quote__list').style.setProperty('--maxQuoteHeight', `${targetHeight}px`);
        // }
        document.querySelector('.toggle-target.active').classList.remove('active');
        document.querySelector('.toggle-trigger.active').classList.remove('active');
        e.target.classList.add('active');
        targetEl.classList.add('active');
    };

    if (slider) {
        new Glide('.glide',{
            type: 'carousel',
            startAt: 0,
            perView: 2,
            peek: {
                before: 150,
                after: 150
            },
            breakpoints: {
                768: {
                    peek: {
                        before: 125,
                        after: 125
                    },
                    perView: 1
                },
                500: {
                    peek: {
                        before: 55,
                        after: 55
                    },
                    perView: 1
                },
                320: {
                    peek: {
                        before: 15,
                        after: 15
                    },
                    perView: 1
                }
            }
        }).mount();
    }

    if (togglerItems.length) {
        togglerItems.forEach((t) => {
            t.addEventListener('click', toggleItem);
        });
    }


    if (elastickStack) {
        // Display only 2 items 

//         const cards = document.querySelectorAll('.card');
//         cards.forEach((c) => {
//             c.addEventListener("click", (e) => {
//                 console.log(e);
//                 e.target.nextElementSibling.style.opacity = 1;
//                 setStylesOnElement({
//                     'opacity': 0,
//                     'visibility': 'hidden'
//                 }, e.target);
//             });

//             var newZ = parseInt(c.style.transform.split(',')[14]) + 10;

//         });

        // $('.card').each(function() {
        //     var newZ = parseInt($(this).css('transform').split(',')[14]) + 10;
        //     var newZIndex = parseInt($(this).css('z-index')) + 1;
        //     $(this).css({
        //       'transform': 'translate3d(-50%, -50%, ' + newZ + 'px)',
        //       'z-index': newZIndex
        //     });
        //     $('.container').append(lastCard);
        //   });
            // varlastCard = ('<div/>', {
            //     "class": 'cardlast-card'
            // }).('.card').each(function () {
            //     varnewZ = parseInt((this).css('transform').split(',')[14]) + 10. varnewZIndex = parseInt((this).css('z-index')) + 1.(this).css({
            //         'transform': 'translate3d(-50%,-50%,' + newZ + 'px)',
            //         'z-index': newZIndex
            //     }).('.container').append(lastCard).
            // }).
    }

    if(document.querySelector('.page-title') && !window.mobileVersion) {

        console.log("splitCharTitle...")
        splitCharTitle()

        // new SplitText(".reveal-text",{linesChars: "line", linesChars: 'line', charClass: "**" });
        // if(document.querySelector('.preloader') && triggerAnim) ScrollReveal().reveal('.reveal-text, .generic-content h2', revealTitle);
        // ScrollReveal().reveal('.reveal-text, .generic-content h2', revealTitle);
    };

    loadContent()

    if(document.querySelector('.dl-wrapper') && !window.mobileVersion) {
        toc(document.querySelector('.dl-wrapper'))
    }



    // Applied globally on all textareas with the "autoExpand" class
    const textareaInfos = () => {
        let _autoExpandEl = document.querySelector(".autoExpand")
        return {
            value: _autoExpandEl.value,
            baseScrollHeight: _autoExpandEl.scrollHeight,
            minRows: parseInt(_autoExpandEl.getAttribute('data-min-rows'), 10)
        }
    }

    const fakeBorder = document.querySelector('.textarea-border');
    const inputs = document.querySelectorAll('.form-hollow input, textarea');

    const autoExpand = (field) => {
        // Reset field height
        field.style.height = 'inherit';

        // Get the computed styles for the element
        let computed = window.getComputedStyle(field);

        // Calculate the height
        let height = field.scrollHeight + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
        fakeBorder.style.height = height + 'px';
        field.style.height = height + 'px';
    };

    inputs.forEach((i) => {
        i.addEventListener('input', function (event) {
            if (event.target.closest('.input-wrapper').classList.contains('filled') && event.target.value === '') {
                event.target.closest('.input-wrapper').classList.remove('filled')
            } else {
                event.target.closest('.input-wrapper').classList.add('filled')
            }
            // event.target.value
            if (event.target.tagName.toLowerCase() !== 'textarea') return;
            autoExpand(event.target);
        }, false);
        i.addEventListener("focus", function (event) {
            event.target.closest('.input-wrapper').classList.toggle('active');
        }, true);
        i.addEventListener("blur", function (event) {
            event.target.closest('.input-wrapper').classList.toggle('active');
        }, true);
    });

    // if (!mobilecheck) {
    //     // Parallax init only for desktop

    //     if(document.querySelector('.rellax')) {
    //         const rellax = new Rellax('.rellax', {
    //             speed: -9,
    //             center: true,
    //             wrapper: null,
    //             round: true,
    //             vertical: true,
    //             horizontal: false
    //         });
    //     };

    //     if(document.querySelector('.rellax-md')) {
    //         const rellax = new Rellax('.rellax-md', {
    //             speed: -4,
    //             center: true,
    //             wrapper: null,
    //             round: true,
    //             vertical: true,
    //             horizontal: false
    //         });
    //     };
    
    //     // if(document.querySelector('.page-content__inner')) {
    //     //     const rellax2 = new Rellax('.page-content__inner', {
    //     //         speed: -7,
    //     //         center: false,
    //     //         wrapper: null,
    //     //         round: true,
    //     //         vertical: true,
    //     //         horizontal: false
    //     //     });
    //     // };
    
        if(document.querySelector('.rellax2')) {
            const rellaxHeaderContent = new Rellax('.rellax2', {
                speed: -5,
                center: false,
                wrapper: null,
                round: true,
                vertical: true,
                horizontal: false
            });
        }
    
        if(document.querySelector('.rlight')) {
            const rellaxLight = new Rellax('.rlight', {
                speed: 1,
                center: true,
                // round: true,
                vertical: true,
                // horizontal: false
            });
        };
        
        if(document.querySelector('.rlight-inverse')) {
            const rellaxLight = new Rellax('.rlight-inverse', {
                speed: -2,
                center: true,
                round: true,
                wrapper: null,
                vertical: true,
                horizontal: false
            });
        };
    
    //     // if(document.querySelector('.blob-image') && !cookie) {
    //     // if(document.querySelector('.blob-container')) {
    //     //     const rellaxLightInverse = new Rellax('.blob-container', {
    //     //         speed: -2,
    //     //         center: true,
    //     //         round: true,
    //     //         vertical: true
    //     //     });
    //     // };
    
    //     if(document.querySelector('.blob-shadow')) {
    //         const rellaxBigBlob = new Rellax('.blob-shadow', {
    //             speed: 1,
    //             center: true,
    //             round: false,
    //             // wrapper: '.block-section'
    //         });
    //     };
    
    //     if(document.querySelector('.rbigblob')) {
    //         const rellaxBigBlob = new Rellax('.rbigblob', {
    //             speed: 2,
    //             center: true,
    //             round: true,
    //             wrapper: null,
    //             vertical: true,
    //             horizontal: false
    //         });
    //     };
    // }
    loadAnimation()
    // if (!getCookie('first_time')) {
    //     loadAnimation()
    // } else {
    //     $('.preloader')?.remove()
    // }

    
    const sceneContainer = document.querySelector('.home-hero__showcase')
    const _conditionalReturn = (value, func) => value || value === 0 ? func(value) : func
    const mapRange = (inMin, inMax, outMin, outMax, value) => {
        let inRange = inMax - inMin,
            outRange = outMax - outMin;
        return _conditionalReturn(value, value => outMin + ((((value - inMin) / inRange) * outRange) || 0));
    }
    const clamp = (min, max, value) => value < min ? min : value > max ? max : value
    const BOUNDS = 100

    if (sceneContainer) {
    
        const getMousePos = (element, proximity, cb) => ({x, y}) => {
            const elementBounds = element.getBoundingClientRect()
            const centerX = elementBounds.left + elementBounds.width / 2
            // console.log('centerX : ', centerX)
            const centerY = elementBounds.top + elementBounds.height / 2
            // console.log('centerY : ', centerY)
            const boundX = mapRange(centerX - proximity, centerX + proximity, -BOUNDS, BOUNDS, x)
            const boundY = mapRange(centerY - proximity, centerY + proximity, -BOUNDS, BOUNDS, y)
            cb(boundX / 100, boundY / 100)
            // const boundX = mapRange(0, window.innerWidth, -BOUNDS, BOUNDS, x)
            // const boundY = mapRange(0, window.innerHeight, -BOUNDS, BOUNDS, y)
            // x: ${Math.floor(boundX) / 100}
            // y: ${Math.floor(boundY) / 100}
            // console.log(mousePos)
            //Checking directional change
            // if(mouse.x > beepos.x){
            //     dir = "right";
            // } else {
            //     dir = "left";
            // }
        }
    
        const updateScene3d = (x, y) => {
            sceneContainer.style.setProperty('--ratio-x', Math.floor(clamp(-80, 60, x * -100))+'rem')
            sceneContainer.style.setProperty('--ratio-y', Math.floor(clamp(-80, 60, y * -100))+'rem')
            sceneContainer.style.setProperty('--rX', `${y * 2}deg`)
            sceneContainer.style.setProperty('--rY', `${x * 2}deg`)
        }
    
        // document.addEventListener('pointermove', getMousePos(sceneContainer,window.innerWidth * 0.5,updateScene3d))
        document.addEventListener('mousemove', getMousePos(sceneContainer,window.innerWidth * 0.85,updateScene3d))
    }
    
        
}

document.addEventListener("DOMContentLoaded", function () {
    initJSEventOnDomReady()
});