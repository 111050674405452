let currentUri

const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    const id = entry.target.getAttribute('id');
    // console.log(entry)
    // if (entry.intersectionRatio > 0.5) {
    if (entry.intersectionRatio > 0.01 && entry.boundingClientRect.y < 140) {
      document.querySelector(`.dl-toc a[href="${currentUri}#${id}"]`).classList.add('active');
    } else {
      document.querySelector(`.dl-toc a[href="${currentUri}#${id}"]`).classList.remove('active');
    }
  });
}, {
  threshold: [0, 1]
});

const toc = (tocWrapper) => {
  // console.log('tocWrapper : ', tocWrapper)
  currentUri = tocWrapper.dataset.currentSlug
  tocWrapper.querySelectorAll('.dl-group[id]').forEach((section) => {
    observer.observe(section);
  });
}

export { toc }