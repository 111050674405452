import { $ } from './_helpers'
/*
	This pen cleverly utilizes SVG filters to create a "Morphing Text" effect. Essentially, it layers 2 text elements on top of each other, and blurs them depending on which text element should be more visible. Once the blurring is applied, both texts are fed through a threshold filter together, which produces the "gooey" effect. Check the CSS - Comment the #container rule's filter out to see how the blurring works!
*/
class morphText {
	constructor(parentSelector) {
		this.parent = parentSelector
		// Controls the speed of morphing.
		this.morphTime = 1;
		this.cooldownTime = 1.25;

		this.morph = 0;
		this.cooldown = this.cooldownTime;
		this.requestId = null

		this.text1 = $('.keyword-now', parentSelector)
		this.text2 = $('.keyword-next', parentSelector)
		this.texts = this.getTexts(parentSelector)
		this.textIndex = this.texts.length - 1;
		this.time = new Date();
	}

	// getElts = (parent) => {
	// 	return {
	// 		text1: $('.keyword-now', parent),
	// 		text2: $('.keyword-next', parent),
	// 	}
	// };

	// The strings to morph between. You can change these to anything you want!
	getTexts = (parent) => {
		return parent.dataset.keywords.split(',')
	}


	doMorph = (elts, texts, textIndex) => {
		this.morph -= this.cooldown;
		this.cooldown = 0;
		
		let fraction = this.morph / this.morphTime;
		
		if (fraction > 1) {
			this.cooldown = this.cooldownTime;
			fraction = 1;
		}
		
		this.setMorph(fraction, elts, texts, textIndex);
	}

	// A lot of the magic happens here, this is what applies the blur filter to the text.
	setMorph = (fraction, elts, texts, textIndex) => {
		// fraction = Math.cos(fraction * Math.PI) / -2 + .5;
		
		this.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
		this.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
		
		fraction = 1 - fraction;
		this.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
		this.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
		
		this.text1.textContent = texts[textIndex % texts.length];
		this.text2.textContent = texts[(textIndex + 1) % texts.length];
	}

	doCooldown = (elts) => {
		this.morph = 0;
		
		this.text2.style.filter = "";
		this.text2.style.opacity = "100%";
		
		this.text1.style.filter = "";
		this.text1.style.opacity = "0%";
	}

	stopLoop = () => {
		if(this.requestId) {
			window.cancelAnimationFrame(this.requestId)
			this.requestId = null
		}
		return
	}
	
	startLoop = () => {
		this.morphTextTransitionLoop()
	}

	morphTextTransitionLoop = () => {
		this.requestId = window.requestAnimationFrame(this.morphTextTransitionLoop);
		let newTime = new Date();
		let shouldIncrementIndex = this.cooldown > 0;
		let dt = (newTime - this.time) / 1000;
		this.time = newTime;
		
		this.cooldown -= dt;
		
		if (this.cooldown <= 0) {
			if (shouldIncrementIndex) {
				this.textIndex++;
			}
			
			this.doMorph(this.elts, this.texts, this.textIndex);
		} else {
			this.doCooldown(this.elts, this.texts);
		}
	}

	/**
	 * Init Fn
	 */
	animateMorphText = (parent) => {

	
		const parentObserver = new IntersectionObserver((entries, observer) => {
			console.log('entries : ', entries)
			
			if(entries[0].isIntersecting) {
				this.startLoop()
			} else {
				this.stopLoop()
			}
		}, { threshold: [0,1] });
	
		
	
	
	
		parentObserver.observe(parent)
	}

	init = () => {
		console.log('init class function from this parent', this.parent)

		if (this.text1) {
			this.text1.textContent = this.texts[this.textIndex % this.texts.length];
			this.text2.textContent = this.texts[(this.textIndex + 1) % this.texts.length];
		}
		this.startLoop()
		document.addEventListener('turbo:before-visit', this.stopLoop, false);
	}

}

const initMorphText = (selector) => {
	
	
	document.querySelectorAll(selector).forEach(el => {
		// console.log('init Morph el : ', el)
		// animateMorphText(el)
		new morphText(el).init()
	})
	// if (heroHeader) stickyTelexObserver.observe(heroHeader)
}

// Start the animation.
export {
	initMorphText
}