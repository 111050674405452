const setCookie = (name, value, days) => {
  let _d = new Date
  _d.setTime(_d.getTime() + 24*60*60*1000*days)
  document.cookie = name + "=" + value + ";path=/;expires=" + _d.toGMTString()
}

const deleteCookie = (name) => {
  setCookie(name, '', -1)
}

const getCookie = (name) => {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
    return v ? v[2] : null
}

export { setCookie, deleteCookie, getCookie };