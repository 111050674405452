const domEl = function (selector) {
	if (document.querySelector(selector)){
		console.log('this : ', this)
		return document.querySelector(selector)
	}else {
		return console.info(`DOM element '${selector}' doesn't exist on this page, consider maybe to remove or split this statement only when the component is called.`)
	}
}

// const $ = function () {
// 	return document.querySelector.bind(document)
// }

const $ = (selector, base = document) => {
	let elements = base.querySelectorAll(selector);
	if (elements.length == 0) return null
	return (elements.length == 1) ? elements[0] : Array.prototype.slice.call(elements, 0);
}

const logInfo = (message) => {
	return console.log(message, 'background: #e6e6e6; color: #33f')
}

const debounce = (callback, wait) => {
	let timeoutId = null;
	return (...args) => {
		window.clearTimeout(timeoutId);
		timeoutId = window.setTimeout(() => {
			callback.apply(null, args);
		}, wait);
	};
}

export {
	$,
	debounce
}