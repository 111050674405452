import ScrollReveal from 'scrollreveal'
import anime from 'animejs/lib/anime.es.js';


let charCounter = []
let charTimer = []

const fadeChar = (charsList, intervalIndex) => {
    let span = charsList[charCounter[intervalIndex]]
    span.classList.add('fade')
    charCounter[intervalIndex]++
    if (charCounter[intervalIndex] === charsList.length) {
        complete(intervalIndex)
        return
    }
};

const complete = (intervalIndex) => {
    clearInterval(charTimer[intervalIndex])
    charTimer.slice(intervalIndex, 1)
    charCounter[intervalIndex] = 0
}

const headerReveal = {
  distance: '20px',
  origin: 'bottom',
  opacity: 0,
  easing: 'cubic-bezier(0.030, 1.035, 0.675, 0.990)',
  duration: 600,
  delay: 375,
  interval: 75,
  mobile: false,
};


const revealHeroImg = {
  // easing: 'cubic-bezier(.895,.03,.685,.22)',
  easing: 'ease-out',
  opacity: 0,
  // cetner: true,
  scale: 1.2,
  mobile: false,
  // viewFactor: 0.5
};


const slideUp = {
  distance: '30px',
  origin: 'bottom',
  center: true,
  easing: 'cubic-bezier(0.030, 1.035, 0.675, 0.990)',
  opacity: 0,
  scale: 1,
  interval: 150,
  mobile: false,
  // viewFactor: 0.1,
  viewOffset: {
      bottom: -200
  },
  delay: 150
};

const slideUpLight = {
  distance: '30px',
  origin: 'bottom',
  // center: true,
  // easing: 'cubic-bezier(.895,.03,.685,.22)',
  opacity: 0,
  scale: 0.8,
  interval: 25,
  mobile: false,
  // viewFactor: 0.1,
  // viewOffset: {
  //     bottom: -200
  // },
  delay: 50
};

const slideUpCaroussel = {
  distance: '60px',
  origin: 'bottom',
  // center: true,
  // easing: 'cubic-bezier(.895,.03,.685,.22)',
  opacity: 0,
  scale: 1,
  interval: 50,
  mobile: false,
  // viewFactor: 0.1,
  delay: 150
};

const revealStack = {
  distance: '345px',
  origin: 'bottom',
  easing: 'cubic-bezier(0.030, 1.035, 0.675, 0.990)',
  opacity: 0,
  scale: 1,
  interval: 40,
  mobile: false,
  viewFactor: 1,
  delay: 350,
  beforeReveal: (el) => {
      anime({
          targets: '.reveal-stagger',
          easing: "cubicBezier(0.030, 1.035, 0.675, 0.990)",
          duration: 750,
          translateY: function(el, i) {
              return 115 + (115 * i);
          },
          opacity: function(el, i) {
              return 0.6 - (0.2 * i);
          },
          delay: function (el, i) {
              return i * 100 + 650
          }
      });
  }
};


let _intervalIndex = 0

const revealTitle = {
    duration: 0,
    easing: 'cubic-bezier(.895,.03,.685,.22)',
    opacity: 0,
    interval: 25,
    mobile: false,
    viewFactor: 1,
    delay: 0,
    // beforeReveal: (el) => {
    // },
    afterReveal: (el) => {
        console.log("el", el)
        const lines = el.querySelectorAll('.char')
        console.log(lines)
        // lines.forEach((el, i) => el.classList.add('line'))
        let intervalIndex = _intervalIndex
        charCounter[intervalIndex] = 0
        charTimer[intervalIndex] = setInterval(() => {
            fadeChar(lines, intervalIndex)
        }, 16);
        if(el.classList.contains('page-title')) ScrollReveal().reveal('.headerReveal', headerReveal)
    }
};

const animSvgPath = (el) => {
    if(el.querySelector('#all')) return
    el.querySelector('svg').style.opacity = 1;

    anime({
        targets: el.querySelectorAll('path'),
        strokeDashoffset: [anime.setDashoffset, 0],
        opacity: 1,
        easing: "easeInOutCirc",
        duration: 1500,
        // delay: function (el, i) {
        //     return i * 250
        // }
    });
}

const checkSvg2Show = (el) => {
    if(!el.querySelector('#all')) return;
    let el2Show = el.querySelector('#all');
    
    if(window.location.hash && listAllCat.indexOf(window.location.hash.replace(/#/gi, '')) !== -1) {
        let idSvg = window.location.hash.replace(/#/gi, '');
        el2Show = el.querySelector(`#icon-${idSvg}`);
    }
    el2Show.classList.add('active');

    anime({
        targets: el2Show.querySelectorAll('path'),
        strokeDashoffset: [anime.setDashoffset, 0],
        opacity: 1,
        easing: "easeInOutCirc",
        duration: 2500,
        delay: function (el, i) {
            return i * 250 + 1000
        }
    });
}

const revealSVG = {
    ezasing: 'cubic-bezier(.895,.03,.685,.22)',
    // distance: '30px',
    // origin: 'top',
    opacity: 0,
    mobile: false,
    delay: 50,
    beforeReveal: animSvgPath,
    // afterReveal: animSvgPath,
};

const revealImage = {
    distance: '40px',
    origin: 'bottom',
    // easing: 'cubic-bezier(.895,.03,.685,.22)',
    opacity: 0,
    scale: 0.8,
    interval: 40,
    mobile: false,
    viewFactor: 0.3,
    delay: 850
};

const slideDown = {
    distance: '30px',
    duration: 1200,
    origin: 'top',
    easing: 'cubic-bezier(.895,.03,.685,.22)',
    opacity: 0,
    interval: 40,
    mobile: false,
    viewFactor: 0.3,
    delay: 250
};

const zoomOut = {
    // distance: '30px',
    duration: 500,
    easing: 'ease-out',
    // opacity: 1,
    scale: 1.4,
    interval: 200,
    mobile: false,
    viewFactor: 0.4,
    delay: 250,
    // beforeReveal: (el) => {
    //     // el.parentNode.style.opacity = 0
    // },
    afterReveal: (el) => {
        el.parentNode.classList.add('reveal')
    }
};

const titleReveal = {
    duration: 650,
    distance: '25px',
    origin: 'left',
    // easing: 'ease-out',
    easing: 'cubic-bezier(0.030, 1.035, 0.675, 0.990)',
    opacity: 0,
    // scale: 0.95,
    interval: 75,
    mobile: false,
    // viewFactor: 0.8,
    viewOffset: {
        bottom: 200
    },
    delay: 50,
    afterReveal: (el) => {
        let subTitle = el.querySelector('.title-sub')
        if(el.querySelector('.title-content u')) el.classList.add('loaded')
        if(subTitle) {
            // subTitle.querySelector('.title-shape').style.opacity = 0
            subTitle.querySelector('.title-shape').style.visibility = 'visible'
            subTitle.querySelectorAll('p').forEach((el) => el.style.visibility = 'visible')
            return anime.timeline({
                easing: 'cubicBezier(0.7,0,0.3,1)',
                // easing: 'cubicBezier(0.030, 1.035, 0.675, 0.990)',
                // easing: 'cubicBezier(.895,.03,.685,.22)',
                autoplay: true,
                // opacity: 1,
            })
            .add({
                targets: subTitle.querySelector('.title-shape'),
                scaleY: [0, 1],
                duration: 400,
            })
            .add({
                targets: subTitle.querySelectorAll('p'),
                easing: 'cubicBezier(0.030, 1.035, 0.675, 0.990)',
                opacity: [0, 1],
                visibility: 'visible',
                translateX: ['-20px', 0],
                duration: 600,
            }, '-=150')
        }
    }
};

export {
  revealTitle,
  headerReveal,
  revealHeroImg,
  slideUp,
  slideUpLight,
  slideUpCaroussel,
  slideDown,
  revealStack,
  revealSVG,
  titleReveal,
  zoomOut,
  revealImage
}