import {
    getCookie,
    setCookie
} from './cookies'
import { $ } from './_helpers'
import ScrollReveal from 'scrollreveal'
import anime from 'animejs/lib/anime.es.js';
import {
    initMorphText
} from './morphText'
// import { revealTitle } from './revealFn'
import {
    revealTitle,
    headerReveal,
    revealHeroImg,
    slideUp,
    slideUpLight,
    slideUpCaroussel,
    slideDown,
    revealStack,
    revealSVG,
    titleReveal,
    zoomOut,
    revealImage
} from './revealFn'



const doNotPreventScroll4Anime = () => {

    if (getCookie('first_time')) {
        // console.log('ok déjà un cookie')
        let dataLoaded = document.querySelectorAll('[data-loaded]')
        // console.log('dataLoaded : ', dataLoaded)
        dataLoaded.forEach(el => {
            // console.log(el)
            el.setAttribute('data-loaded', true)
        })
    }

}


/**
 * ****
 * PRELOADER ANIMATION ON LOGO
 * ****
 */

const loadAnimation = () => {

    //   if (!getCookie('first_time')) {
    console.log('anim preloader')
    let triggerHeader = false
    let debug = !!document.querySelector('.timeline-controls-demo')
    let controlsProgressEl = document.querySelector('.timeline-controls-demo .progress')
    const logoCoords = document.querySelector('.link-logo').getBoundingClientRect()
    console.log('logoCoords : ', logoCoords)

    const controlProgress = (progress) => {
        controlsProgressEl.value = progress
        document.querySelector('.timeline-controls-demo .time').innerHTML = `${(logoTimeline.currentTime / 1000).toFixed(2)}s / ${(logoTimeline.duration / 1000).toFixed(2)}s`;
    }

    let logoTimeline = anime.timeline({
        easing: 'spring(0, 30, 10, 0)',
        autoplay: true,
        update: () => {
            if (debug) controlProgress(logoTimeline.progress);
        }
    })

    if (debug) {
        document.querySelector('.timeline-controls-demo .play').onclick = logoTimeline.play;
        document.querySelector('.timeline-controls-demo .pause').onclick = logoTimeline.pause;
        document.querySelector('.timeline-controls-demo .restart').onclick = logoTimeline.restart;

        controlsProgressEl.addEventListener('input', function () {
            logoTimeline.seek(logoTimeline.duration * (controlsProgressEl.value / 100));
        });
    }

    // let realLogo = document.querySelector('.link-logo')
    // realLogo.style.opacity = 0
    //    document.querySelector('.preloader .mountain').style.fill = 'transparent'
    // document.querySelector('.preloader .mountain').style.stroke = 'white'
    // document.querySelector('.preloader #separator_line').style.opacity = 0
    // document.querySelector('.preloader #loadingLogo').style.transform = 'scale(1.25)'

    /** Animate First Mountain */
    logoTimeline
        .add({
            targets: '.preloader .userstories-logo',
            opacity: 1,
            easing: 'linear',
            duration: 0
        })
        //    .add({
        //        targets: '.preloader #loadingLogo',
        //        scale: 1.25,
        //        easing: 'linear',
        //        duration: 0
        //    })
        .add({
            targets: '.preloader .logoStagger',
            opacity: [0, 1],
            easing: 'linear',
            delay: anime.stagger(50),
            duration: 500
        })
        //    .add({
        //         targets: '.preloader',
        //         easing: 'linear',
        //         delay: anime.stagger(50),
        //         duration: 500
        //     })
        .add({
            targets: '.preloader .logoStagger',
            translateY: ['250px', 0],
            // opacity: [0, 1],
            easing: 'cubicBezier(0.7,0,0.3,1)',
            delay: anime.stagger(50),
            duration: 750
        }, '-=450')


        /** Animate Mountains */
        .add({
            targets: '.preloader .mountain',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(0.7,0,0.3,1)',
            duration: 600
        }, '-=200')
        .add({
            targets: '.preloader .mountain',
            fill: ['#ffffff00', '#ffffff'],
            easing: 'cubicBezier(0.7,0,0.3,1)',
            duration: 600
        }, '-=400')


        /** Animate breakLine after all */
        .add({
            targets: '.preloader #separator_line',
            scaleX: [0, 1],
            //    strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(0.7,0,0.3,1)',
            duration: 600
        }, '-=700')
        .add({
            targets: '.preloader #separator_line',
            opacity: [0, 1],
            //    strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(0.7,0,0.3,1)',
            duration: 400
        }, '-=700')

        .add({
            targets: '.preloader .label',
            opacity: [0, 1],
            translateX: ['-30px', 0],
            duration: 400,
        }, '-=350')
        //    .add({
        //        targets: '.preloader #text2',
        //        opacity: [0, 1],
        //        duration: 400,
        //        translateY: ['-15px', 0],
        //    }, '-=700')
        //    .add({
        //        targets: '.section-blobBg',
        //        duration: 1000,
        //        // easing: 'cubicBezier(0.7,0,0.3,1)',
        //        easing: 'cubicBezier(0.030, 1.035, 0.675, 0.990)',
        //        scale: [4, 1],
        //        rotate: ['45deg', 0],
        //        update: (anim) => {
        //            if(Math.round(anim.progress) > 90 && !triggerHeader) {
        //                document.querySelector('.main-header').classList.remove('loading')
        //                triggerHeader = true
        //            }
        //        },
        //    }, '-=300')

        .add({
            targets: '.preloader #loadingLogo',
            duration: 1000,
            scale: [1.25, 1],
            elasticity: '1000',
            easing: 'cubicBezier(0.7,0,0.3,1)'
            //    complete: (anim) => {
            //        realLogo.style.opacity = 1
            //     //    document.querySelector('.preloader').remove()
            //     //    triggerAnim = true
            //        // ScrollReveal().reveal('.header-hero .reveal-text', revealTitle);
            //        ScrollReveal().reveal('.header-hero .reveal-text', revealTitle);
            //        // const rellaxLightUp = new Rellax('.section-blobBg', {
            //        //     speed: 5,
            //        //     center: true,
            //        //     // round: true,
            //        //     // vertical: true
            //        // });
            //        // const rellaxLightDown = new Rellax('.bgtest2', {
            //        //     speed: -3,
            //        //     center: true,
            //        //     // round: true,
            //        //     // vertical: true
            //        // });
            //    }
        }, '-=1350')
        .add({
            targets: '.preloader-title',
            opacity: [0, 1],
            translateY: ['30px', 0],
            duration: 400,
        }, '-=350')
        .add({
            targets: '.preloader .link',
            opacity: [0, 1],
            translateY: ['30px', 0],
            duration: 400,
        }, '-=750')

        /**
         * Second part - leave anmiation
         */
        // .add({
        //     targets: '.preloader-layer',
        //     duration: 550,
        //     // opacity: [1, 0],
        //     translateY: [0, '-100%'],
        //     scale: [1.25, 1],
        //     // easing: 'cubicBezier(.5, .05, .1, .3)',
        //     delay: anime.stagger(50),
        //     easing: 'easeInCubic',
        // }, '-=250')
        // .add({
        //     targets: '.preloader .logo-element',
        //     translateY: [0, '-250px'],
        //     // easing: 'cubicBezier(0.7,0,0.3,1)',
        //     easing: 'easeInQuad',
        //     delay: anime.stagger(-25),
        //     duration: 450,
        //     complete: (anim) => {
        //         document.querySelector('.preloader').remove()
        //         ScrollReveal().reveal('.header-hero .reveal-text', revealTitle);

        //         let dataLoaded = document.querySelectorAll('[data-loaded]')
        //         dataLoaded.forEach(el => {
        //             el.setAttribute('data-loaded', true)
        //         })
        //         setCookie('first_time', false, 1)
        //     }
        // }, '-=325')
        // .add({
        //     targets: '.preloader #loadingLogo',
        //     scale: 1,
        //     top: logoCoords.y,
        //     left: logoCoords.x,
        //     duration: 750,
        //     complete: (anim) => {
        //         realLogo.style.opacity = 1
        //         // document.querySelector('.preloader').remove()
        //         // ScrollReveal().reveal('.header-hero .reveal-text', revealTitle);

        //         let dataLoaded = document.querySelectorAll('[data-loaded]')
        //         dataLoaded.forEach(el => {
        //             el.setAttribute('data-loaded', true)
        //         })
        //         //    setCookie('first_time', false, 30)
        //     }
        // }, '-=500')


    //    .add({
    //      targets: '.landing-scene .landing-bg',
    //      opacity: [0, 1],
    //      scale: [0.95, 1],
    //      duration: 3500,
    //    }, '-=300')

    //    .add({
    //      targets: '.landing-scene span',
    //      opacity: [0, 1],
    //      scale: [0.25, 1],
    //      duration: 450,
    //      delay: anime.stagger(150),
    //      complete: (anim) => {
    //        if (document.querySelector('.landing-scene')) {
    //            document.querySelector('.landing-scene').classList.add('loaded')
    //        }

    //        let dataLoaded = document.querySelectorAll('[data-loaded]')
    //        dataLoaded.forEach(el => {
    //            el.setAttribute('data-loaded', true)
    //        })
    //        setCookie('first_time', false, 30)
    //      }
    //    }, '-=400')

    //    .add({
    //        targets: '.main-nav--item',
    //        delay: anime.stagger(55),
    //        easing: 'cubicBezier(0.7,0,0.3,1)',
    //        opacity: [0, 0.45],
    //        scale: [0.9, 1],
    //        duration: 650,
    //        complete: (anim, el) => {
    //            document.querySelectorAll('.main-nav--item').forEach((el) => el.style = "")
    //            document.querySelector('.main-nav').classList.add('loaded')
    //        }
    //      }, '-=1000')

    //    .add({
    //        targets: '.main-nav > .cta',
    //        easing: 'cubicBezier(0.7,0,0.3,1)',
    //        opacity: [0, 1],
    //        translateY: ['-10px', 0],
    //        duration: 650,
    //        complete: (anim) => document.querySelector('body').classList.remove('overflow-hidden')
    //      }, '-=1000')
    //  }
    //  else {
    //    /**
    //     * Assuming cookie has been set and first Loader animation has already been saw
    //     * Force ScrollReveal HeroHeader element to appear
    //     */
    //    let triggerHeaderAimation = false

    //    let animSimple =  anime.timeline({
    //        easing: 'spring(0, 30, 10, 0)',
    //        autoplay: true
    //    })

    //    // Add Background white shape for let appearing the text
    //    animSimple
    //    .add({
    //        targets: '.section-blobBg',
    //        duration: 650,
    //        easing: 'cubicBezier(0.030, 1.035, 0.675, 0.990)',
    //        scale: [1.5, 1],
    //        opacity: document.body.classList.contains('page') ? [0, 0.1] : [0, 1],
    //        rotate: ['45deg', 0],
    //        // delay: '100',
    //        update: function(anim) {
    //            if (!window.mobileVersion && anim.progress > 50 && !triggerHeaderAimation) {
    //            // if (anim.progress > 50 && !triggerHeaderAimation) {
    //                triggerHeaderAimation = true
    //                ScrollReveal().reveal('.header-hero .reveal-text', revealTitle)
    //            }
    //        }
    //        // complete: (anim) => ScrollReveal().reveal('.header-hero .reveal-text', revealTitle)
    //    })

    //    if (document.querySelector('.landing-scene')) {
    //        animSimple
    //        .add({
    //            targets: '.landing-scene .landing-bg',
    //            opacity: [0, 1],
    //            scale: [0.95, 1],
    //            duration: 1000,
    //        }, '-=600')
    //        .add({
    //            targets: '.landing-scene span',
    //            opacity: [0, 1],
    //            scale: [0.25, 1],
    //            duration: 750,
    //            delay: anime.stagger(150),
    //            complete: (anim) => {
    //                document.querySelector('.landing-scene').classList.add('loaded')
    //            }
    //        }, '-=800')
    //    }

    //    return animSimple
    //  }
}

const loadContent = () => {
    if (document.querySelector('.page-title')) ScrollReveal().reveal('.page-title', revealTitle);
    if (document.querySelector('.reveal-text')) ScrollReveal().reveal('.reveal-text', headerReveal);
    if (document.querySelector('.title')) ScrollReveal().reveal('.title', titleReveal);
    if (document.querySelector('.slideUp')) ScrollReveal().reveal('.slideUp, .generic-content p', slideUp);
    if (document.querySelector('.slideUpLight')) ScrollReveal().reveal('.slideUpLight', slideUpLight);
    if (document.querySelector('.zoomOut')) ScrollReveal().reveal('.zoomOut', zoomOut);
    if (document.querySelector('.glide')) ScrollReveal().reveal('.slideUpCaroussel', slideUpCaroussel);
    if (document.querySelector('.popImage')) ScrollReveal().reveal('.popImage', revealImage);
    if (document.querySelector('.slideDown')) ScrollReveal().reveal('.slideDown', slideDown);
    if (document.querySelector('.revealSVG')) ScrollReveal().reveal('.revealSVG', revealSVG);
    if (document.querySelector('.reveal-stack')) ScrollReveal().reveal('.reveal-stack', revealStack);
    if (document.querySelector('.reveal-img')) ScrollReveal().reveal('.reveal-img', revealHeroImg);
    // console.log('[data-morphing-text]', $('[data-morphing-text]'))
    if ($('[data-morphing-text]')) initMorphText('[data-morphing-text]')
    // if (document.querySelector('#morphFilters')) animateMorphText();
}

export {
    doNotPreventScroll4Anime,
    loadAnimation,
    loadContent
}