export default class IntervalTimer {
  callbackStartTime;
  remaining = 0;
  paused = false;
  timerId = null;
  _callback;
  _delay;

  constructor(callback, delay) {
    this._callback = callback;
    this._delay = delay;
  }

  pause() {
    // console.log('Pause timer ...')
    if (!this.paused) {
      this.clear();
      this.remaining = this._delay - (new Date().getTime() - this.callbackStartTime);
      // console.log("remaining... : ", this.remaining /  1000 + 's')
      this.paused = true;
    }
  }

  resume() {
    // console.log('resume... ', this.remaining, this._delay)
    if (this.paused) {
      if (this.remaining) {
        setTimeout(() => {
          this.run();
          this.paused = false;
          this.start();
        }, this.remaining);
      } else {
        this.paused = false;
        this.start();
      }
    }else {
      this.start()
    }
  }
  
  reset() {
    this.paused = false;
    this.callbackStartTime = null
    this.clear();
  }

  clear() {
    clearInterval(this.timerId);
  }

  start() {
    this.clear();
    if(!this.callbackStartTime) this.callbackStartTime = new Date().getTime();
    // console.log('this._delay : ', this._delay)
    this.timerId = setInterval(() => {


      this.run();
    }, this._delay);
  }

  run() {
    this.callbackStartTime = new Date().getTime();
    // console.log('this.callbackStartTime : ', this.callbackStartTime)
    this._callback();
  }
}