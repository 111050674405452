import anime from 'animejs/lib/anime.es.js';
import Timer from './intervalTimer'

var hidden, visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

const initSlider = (parent) => {
  let sliderNav = parent.querySelector('.vSlider-nav')
  let sliderButtons = parent.querySelectorAll('.vSlider-nav button')
  let sliderItems = parent.querySelectorAll('.vSlider-item')
  let globalIdx = null
  let timerDelay = 6000
  let sliderVisible = false

  let switchSlide = (currentIndex, newIndex, sliderButtons, sliderItems) => {
    sliderItems[currentIndex].classList.add('in-transition')
    setTimeout(() => {
      sliderItems[currentIndex].classList.remove('in-transition')
      sliderItems[currentIndex].classList.remove('active')
      sliderItems[newIndex].classList.add('active')
    }, 350);
    sliderButtons[currentIndex].classList.remove('active')
    sliderButtons[newIndex].classList.add('active')
    globalIdx = newIndex
    sliderNav.style.setProperty("--activeIndex", newIndex);
  }

  sliderButtons.forEach((el, idx) => {
    /** For first init since active will be added from back office  */
    if(el.classList.contains('active')) {
      globalIdx = idx
      sliderNav.style.setProperty("--activeIndex", idx);
    }

    el.addEventListener('click', function(e) {
      indicatorLine.reset()
      autoplay.reset()
      let newIndex = idx
      if(newIndex === globalIdx) return
      switchSlide(globalIdx, newIndex, sliderButtons, sliderItems)
    })
  })


  let autoplay = new Timer(function() {
    if(globalIdx < sliderButtons.length - 1) switchSlide(globalIdx, globalIdx + 1, sliderButtons, sliderItems)
    else switchSlide(globalIdx, 0, sliderButtons, sliderItems)
    // vSlider-blob
  }, timerDelay);

  let indicatorLine = anime({
    targets: '.vSlider-blob-line',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: timerDelay,
    // delay: function(el, i) { return i * 250 },
    direction: 'alternate',
    autoplay: false,
    loop: true
  });

  const pauseSlider = () => {
    // console.log('pause the slider ')
    autoplay.pause()
    indicatorLine.pause()
  }
  
  const resumeSlider = () => {
    // console.log('resume the slider ')
    if (sliderVisible) {
      autoplay.resume()
      indicatorLine.play()
    }
  }

  if(!!window.IntersectionObserver){

    let observeSlider = new IntersectionObserver((entries, observer) => {
        // console.log(entries)
        // no intersection with screen
        if(entries[0].intersectionRatio === 0) {
          sliderVisible = false
          pauseSlider()
        }
        // fully intersects with screen
        else if(entries[0].intersectionRatio === 1) {
          sliderVisible = true
          resumeSlider()
        }
    }, { threshold: [0,1] });
    
    if (sliderNav) observeSlider.observe(sliderNav)
  }

  /** Init the slider */
  autoplay.start()
  indicatorLine.play()


  parent.addEventListener("mouseenter", () => {
    pauseSlider()
  }, false);
  parent.addEventListener("mouseleave", () => {
    resumeSlider()
  }, false);

  function handleVisibilityChange() {
    if (document.hidden) {
      pauseSlider()
    } else {
      resumeSlider()
    }
  }

  document.addEventListener(visibilityChange, handleVisibilityChange, false);
  
  document.addEventListener('turbo:before-visit', autoplay.clear(), false);



}



export { initSlider };